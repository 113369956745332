// @format
// @flow
import React from 'react'
import styled from 'styled-components'
import g from 'glamorous'
import { withPrefix } from 'gatsby-link'
import { Button } from '../elements'
import { readableColor } from 'polished'

const PButton = styled(Button)<{ color: string }>`
  background: ${props => (props.color === 'white' ? 'black' : props.color)};
  color: ${props => readableColor(props.color === 'white' ? 'black' : props.color)};
`

const ContactForm = () => (
  <form
    action="https://formspree.io/info@solipsisdev.com"
    method="POST"
  >
    <input type="hidden" name="_subject" value="Contact Form" />
    <input name="_next" value={withPrefix(`/thanks`)} type="hidden" />

    <div>
      <p>
        Enter your info into the form below and we'll try to get back to you ASAP!
      </p>
    </div>

    <div>
      <label>
        Name
      </label>
      </div>
      <div>
      <input name="name" id="name" type="text" />
    </div>
    <div>
      <label>
        Email
      </label>
      </div>
      <div>
      <input name="_replyTo" id="_replyTo" type="email" />
    </div>
    <div>
    <label>
      Message
    </label>
    </div>
    <div>
    <textarea
      name="message"
      id="message"
      placeholder="Your message"
      cols="80"
      rows="10"
      required
    />
    </div>

    <div>
      <PButton color="#333">Send</PButton>
    </div>
  </form>
)

export default ContactForm
