import React from 'react'
import logo from '../images/logo.png'
import logoInverted from '../images/logo-inverted.png'

const Logo = (props) => {
  let img = props.color == "white" ? logoInverted : logo;
  return <img src={img} alt="Logo for Solipsis Development" height="128" width="128"></img>
}

export default Logo
